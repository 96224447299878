export const getRublesFromPennies = (number: number | undefined) => {
    if (typeof number === 'number') {
        return (number / 100).toLocaleString('ru-RU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        })
    }
    return '0'
}

export const getIntegerPartFromPennies = (number: number | undefined) => {
  if (typeof number === 'number') {
    return Math.floor(number / 100).toString()
  }
  return '0'
}

export function splitNumberIntoThousands(value: number): string {
    const thousands = []
    let num = value

    if (num === 0) {
        return '0'
    }

    while (num > 0) {
        const t = num % 1000
        num = (num - t) / 1000
        thousands.push(num > 0 ? `${t}`.padStart(3, '0') : `${t}`)
    }

    return thousands.reverse().join(' ')
}

export const getIntegerPart = (number: number | string = '', withSign?: boolean) => {
    const stringNumber: string = typeof number === 'number' ? getRublesFromPennies(number) : number
    return `${withSign && number > 0 ? '+' : ''}${stringNumber
        .split(',')[0]
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`
}
export const getFactorialPart = (number: number | string = '', withoutSymbol?: boolean) => {
    const stringNumber: string = typeof number === 'number' ? getRublesFromPennies(number) : number
    return `,${stringNumber.split(',')?.[1] ?? '00'}${withoutSymbol ? '' : '  ₽'}`
}

export const currentNumber = (value: string) => {
    if (value) {
        return value.replace(/\D+|^0+/g, '')
    }
    return value
}

export const currentFloatNumber = (value: string) => {
    if (value) {
        return value.replace(/[^\d.,]|^(\.|,)+/g, '')
    }
    return value
}

export const spreadNumber = (value: string) => {
    if (value) {
        return value.replace(/^0+|[^0-9.]/g, '')
    }
    return value
}
