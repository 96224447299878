import { IDfaFront } from '@dltru/dfa-models'
import moment from 'moment'
import { FormInstance } from 'antd'

export const prepareInitialDfa = (dfa = {} as IDfaFront) => ({
    ...dfa,
    issue_start_date: dfa.issue_start_date ? moment(dfa.issue_start_date) : dfa.issue_start_date,
    redeem_date: dfa.redeem_date ? moment(dfa.redeem_date) : dfa.redeem_date,
    issue_end_date: dfa.issue_end_date ? moment(dfa.issue_end_date) : dfa.issue_end_date,
    min_supply_invested: !!dfa.min_supply_invested ? dfa.min_supply_invested : null,
    max_supply_invested: !!dfa.max_supply_invested ? dfa.max_supply_invested : null,
    investment_spread: !!dfa.investment_spread ? dfa.investment_spread : null,
    redemption_spread: !!dfa.redemption_spread ? dfa.redemption_spread : null,
})

export const calcPricePerDfa = (form: FormInstance) => {
    const spread = Number(form.getFieldValue('investment_spread'))
    const priceExternal = Number(form.getFieldValue('price_external'))
    if (!spread || !priceExternal) return

    const pricePerDfa = (priceExternal / 100) * spread + priceExternal
    form.setFieldsValue({ price_per_dfa: pricePerDfa.toFixed(2) })
}
