export const addSnilsMaskToString = (snils?: string) => {
    if (!snils) {
        return ''
    }
    const value = String(snils).replace(/\D/g, '')
    return value.length === 11
        ? `${value[0]}${value[1]}${value[2]}-${value[3]}${value[4]}${value[5]}-${value[6]}${value[7]}${value[8]} ${value[9]}${value[10]}`
        : value
}

export const onlyDigits = (value: string) => {
    if (value) {
        return value.replace(/\D+/g, '')
    }
    return value
}

export const onlyDigitsAndMinus = (value: string) => {
  if (value) {
    return value.replace(/[^-\d]+/g, '').replace(/(?!^)-/g, "")
  }
  return value
}

export const addOkatoMaskToString = (okato: string) => {
    return okato
        .split('')
        .map((char, index, array) => {
            if ((index === 1 || index % 3 === 1) && index !== array.length - 1) {
                return `${char} `
            }
            return char
        })
        .join('')
}
